<template>
<svg
  v-once
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#woot-logo-clip-2342424e23u32098)">
    <path
      d="M1.328,0 C0.595,0 0,0.629 0,1.407 L0,16 L3.96,12.488 L15.984,12.488 C16.717,12.488 17.312,11.859 17.312,11.081 L17.312,1.407 C17.312,0.629 16.717,0 15.984,0 L1.328,0 Z"
      fill="#8A5CF6"
    />
    <path
      d="M4.847,5.667 C5.421,5.667 5.897,6.108 5.897,6.641 C5.897,7.173 5.421,7.615 4.847,7.615 C4.273,7.615 3.797,7.173 3.797,6.641 C3.797,6.108 4.273,5.667 4.847,5.667 Z"
      fill="#FFFFFF"
    />
    <path
      d="M11.255,5.666 C11.829,5.666 12.305,6.108 12.305,6.641 C12.305,7.174 11.829,7.616 11.255,7.616 C10.681,7.616 10.205,7.174 10.205,6.641 C10.205,6.108 10.681,5.666 11.255,5.666 Z"
      fill="#FFFFFF"
    />
    <path
      d="M7.852,5.684 C8.426,5.684 8.902,6.126 8.902,6.659 C8.902,7.191 8.426,7.633 7.852,7.633 C7.278,7.633 6.802,7.191 6.802,6.659 C6.802,6.126 7.278,5.684 7.852,5.684 Z"
      fill="#FFFFFF"
    />
  </g>
  <defs>
    <clipPath id="woot-logo-clip-2342424e23u32098">
      <rect width="16" height="16" fill="white" />
    </clipPath>
  </defs>
</svg>
</template>
